<template>
    <div class="order-admin">
        <div v-if="showslip==true" class="navbar is-fixed-top has-background-light not-printed">
            <div class="navbar-start">
                <span class="navbar-item">
                    <button @click="showslip=false; orderIds=[]" class="button not-printed">Close</button>
                </span>
            </div>
            <div class="navbar-end">
                <span class="navbar-item"><button @click="print" class="button is-primary not-printed">Print</button></span>
            </div>
        </div>

        <packing-slip-display :orderIds="selected" v-if="showslip==true"></packing-slip-display>
        <div class="navbar is-info mb-6 not-printed">
            <div class="navbar-brand">
                <search></search>
            </div>
        </div>
        <div class="container not-printed">
            <div class="columns pb-5">
                <div v-if="!editorder" class="column"><input class="input" type="datetime-local" v-model="from" /></div>
                <div v-if="!editorder" class="column"><input class="input" type="datetime-local" v-model="to" /></div>
                <div v-if="!editorder" class="column is-narrow"><button @click="fetchorders" class="button">Submit</button></div>
                <div v-else>
                    <div><button class="button" @click="() => {editorder=null; selected=[];}">Back</button>  <h1 class="crumb-item">{{editorder.order_reference.substr(0, 11)}}</h1></div>
                </div>
                <div class="column has-text-right">
                    <button v-if="editorder" class="button" @click="() => {editorder=null; selected=[];}">Back</button>&nbsp;
                    <button @click="showslip=true" class="button" :class="{'is-primary':selected.length > 0 }" :disabled="selected.length==0?true:false">Print Slip</button>&nbsp; 
                    <button @click="dispatchselection" class="button" :class="{'is-danger':selected.length > 0 }" :disabled="selected.length==0?true:false">Dispatch</button>
                </div>
                
            </div>
        </div>
        <div class="container not-printed">
            <template v-if="!editorder">
                <div class="columns is-striped" v-for="(ord, i) in orders" :key="i">
                    <div class="column"><strong>{{printdate(ord.dateString)}}</strong> <br /><span class="is-size-6">{{ord.orderRef.substr(0,11)}}</span></div>
                    <div class="column">
                        <strong>{{ord.name}}</strong><br />
                        <a :href="`mailto:${ord.email}`">{{ord.email}}</a>
                    </div>
                    <div class="column">&pound; {{ord.total.toFixed(2)}} <br />+&pound;{{ord.deliveryCost.toFixed(2)}}</div>
                    <div class="column">{{ord.deliveryName}}</div>
                    <div class="column"><strong>{{ord.deliveryTo}}</strong></div>
                    <div class="column is-narrow">
                        <span :class="{'ok': ord.orderStatus==20|| ord.orderStatus==21}" class="ad-icon print"></span>
                    </div>
                    <div class="column is-narrow">
                        <span :class="{'ok': ord.orderStatus==21}" class="ad-icon dispatch"></span>
                    </div>
                    <div class="column is-narrow">
                        <label class="checkbox-label">
                            <input type="checkbox" :disabled="disabled" v-model="selected" :value="ord.id" />
                            <span></span>
                        </label>
                    </div>
                    <div class="column is-narrow">
                        <button @click="fetchorder(ord.id)" class="button is-primary">View</button>
                    </div>
                </div>
            </template>
            <template v-else>
                <order-edit :order="editorder">

                </order-edit>
            </template>
        </div>
    </div>
</template>

<style>
    .ad-icon {
        display: inline-block;
        width: 2rem; height: 2rem;
        background-image: url(/admin-sprite.svg);
        background-size: 12rem 2rem;
        background-repeat: no-repeat;
    }
    .ad-icon.print {
        background-position-x: -8rem;
    }
    .ad-icon.dispatch {
        background-position-x: 0rem;
        width: 3rem;
    }
    .ad-icon.print.ok {
        background-position-x: -10rem;
    }
    .ad-icon.dispatch.ok {
        background-position-x: -3rem;
    }
    .crumb-item { line-height: 2.25rem; display:inline-block; padding: 0 1rem; }
    .order-admin {
        margin: 3.25rem 0 8rem;
    }
    .checkbox-label { display: flex; position: relative; border-radius: 0.25rem; 
                      overflow:hidden; align-items: center; justify-content: center; width: 2.75rem; height: 2.75rem; 
                      box-shadow: 0px 0px 0px 2px inset #00000021; cursor: pointer; }
    .checkbox-label:hover { background-color: #cdffe9; }
    .checkbox-label input { cursor: pointer; position: relative; z-index: 2; }
    .checkbox-label span { position: absolute; top:0; left: 0; right:0; bottom:0; z-index: 1; }
    input:checked +span { background-color: #36ab46; box-shadow: 0px 0px 0px 2px inset #00000021; }
    .order-actions { margin: 1.5rem 0 }
    @media print {
        .not-printed { display: none !important; }
    }
</style>

<script>
    import axios from 'axios'
    import Search from './Search.vue'
    import OrderEdit from './OrderEdit.vue'
    import PackingSlipDisplay from './PackingSlipDisplay'

    export default {
        name: "OrdersAdmin",
        components: {
            OrderEdit,
            Search,
            PackingSlipDisplay
            //YesNoBlob
        },
        data() {
            return {
                orders: [],
                from: '',
                to: '',
                selectedorder: null,
                editorder: null,
                selected: [],
                showslip: false,
                dispatches: null,
                disabled: false
            }
        },
        methods: {
            fetchorders() {
                axios.get(`/api/order?f=${this.from}&t=${this.to}`)
                    .then((response) => {
                        this.orders = response.data.orderSummaries;
                        this.from = response.data.from.substr(0, 16);
                        this.to = response.data.to.substr(0, 16);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            fetchorder(id) {
                axios.get(`/api/order/${id}`)
                    .then((response) => {
                        this.selected = [response.data.id];
                        this.editorder = response.data;
                        this.editorder.basket.basketItems.forEach((b, i) => {
                            let si = b.sku.skuProductImages.length ? b.sku.skuProductImages.sort((a, b) => a.sequence - b.sequence)[0] : null
                            let pi = b.sku.product.productImages.length ? b.sku.product.productImages.sort((a, b) => a.sequence - b.sequence)[0] : null

                            b.mainImage = (si ? si.productImage : null) || pi
                        })
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            printdate(json) {
                let d = new Date(json)
                return d.toLocaleDateString('en-gb', { year: 'numeric', month: 'short', day: 'numeric' })
            },
            print: function() {
                window.print();
            },
            dispatchselection(ev) {
                this.disabled = true
                ev.target.classList.add('is-loading')
                axios.post(`/api/order/dispatch/`, this.selected)
                    .then((response) => {
                        if (response && response.data) {
                            this.dispatches = response.data;
                            this.selected
                                .forEach((v) => {
                                    let o = this.orders.find((o) => o.id == v)
                                    let d = this.dispatches.find((o) => o.id == v)
                                    if (o) {
                                        if (d) {
                                            if (d.ok == true)
                                                o.orderStatus = 21
                                            else
                                                o.orderStatus = 22
                                        } //else
                                        //  o.orderStatus == 
                                    }
                                })
                            this.selected = []
                        } else
                            alert('No Orders were dispatched. Maybe already done?')

                        ev.target.classList.remove('is-loading')
                        this.disabled = false

                    })
                    .catch(function (error) {
                        console.log(error);
                        this.disabled = false
                    });
            }
        },
        mounted() {
            this.fetchorders()
        },
        watch: {
            showslip(n, p) {
                if (n === true && p === false) {
                    this.orders.filter((o) => this.selected.includes(o.id))
                        .forEach((v) => {
                            if (![21, 30].includes(v.orderStatus))
                                v.orderStatus = 20
                        })
                } else {
                    this.selected = []
                }
            }
            //selectedorder(nv) {
            //    if(nv)
            //        this.fetchorder(nv.id);
            //}
        }
    }
</script>
