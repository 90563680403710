<template>
    <div class="product-admin">
        <div class="navbar is-info mb-6">
            <div class="navbar-brand">

            </div>
            <div class="navbar-menu">
                <div class="navbar-start">
                </div>
                <div class="navbar-end">
                    <div class="navbar-item">
                        <div class="field is-grouped">
                            <p class="control">
                                <button v-if="ontab=='sets'" class="button" @click="newset">Add New</button>
                                <button v-else class="button" @click="newpersonalisation">Add New</button>
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="container">
            <div class="tabs is-centered">
                <ul>
                    <li @click="ontab='sets'" :class="{'is-active':ontab=='sets'}"><a>Sets</a></li>
                    <li @click="ontab='pers'" :class="{'is-active':ontab=='pers'}"><a>Personalisations</a></li>
                </ul>
            </div>
            <template v-if="ontab=='sets'">

            <div v-for="set in personalisationsets" :key="set.id" class="columns bordered-set">
                <strong v-if="selectedset==set" class="column is-one-third">
                    <input class="input" v-model="set.name" /><br />
                </strong>
                <strong class="column is-one-third" v-else>
                    {{set.name}}
                </strong>
                <div class="column">
                    <div class="columns bordered-row" :key="pr.id" v-for="pr in set.personalisationSetPersonalisations.sort((a, b) => { return a.sequence > b.sequence ? 1 : a.sequence == b.sequence ? 0 : -1})">
                        <div class="column">
                            <strong>{{pr.personalisation.name}}</strong>
                            <br />
                            <small>{{pr.personalisation.note}}</small>
                            <br />
                            <template v-if="selectedset==set">
                                <label>
                                    <input type="radio" :name="`pr${pr.id}-${pr.personalisation.id}`" :value="true" v-model="pr.required" />
                                    required
                                </label>
                                <label>
                                    <input type="radio" :name="`pr${pr.id}-${pr.personalisation.id}`" :value="false" v-model="pr.required" />
                                    optional
                                </label>
                            </template>
                            <template v-else>
                                <br /><span v-if="pr.required == false" class="tag">Optional</span>
                            </template>
                        </div>
                        <template v-if="pr.personalisation.personalisationOptions.length">
                            <span class="column tags">
                                <span :key="i" v-for="(op, i) in pr.personalisation.personalisationOptions" class="tag is-warning">
                                    {{op.name}}
                                </span>
                            </span>
                        </template>
                        <div class="column" v-else>
                            <span v-if="pr.personalisation.personalisationInputType==1" class="tag is-warning">Free Text Entry</span>
                            <span v-if="pr.personalisation.personalisationInputType==2" class="tag is-warning">Number Value</span>
                            <span v-if="pr.personalisation.personalisationInputType==3" class="tag is-warning">True/False Value</span>
                        </div>
                        <div class="column is-narrow" v-if="selectedset==set">
                            <input type="number" step="100" class="input is-small" v-model="pr.sequence" />
                        </div>
                        <div class="column is-narrow" v-else>
                            {{pr.sequence}}
                        </div>
                        <div class="column is-narrow">
                            <button @click="removefromset(pr.personalisation.id)" v-if="selectedset==set" class="delete"></button>
                        </div>
                    </div>
                    <div v-if="selectedset==set" class="field has-addons" style="margin-left: -0.75rem;">
                        <p class="control">
                            <span class="select">
                                <select v-model="toaddtoset">
                                    <option :value="null">Choose... </option>
                                    <option v-for="p in personalisations.filter((p) => !set.personalisationSetPersonalisations.some((psp)=> p.id==psp.personalisation.id))" :value="p">{{p.name}}</option>
                                </select>
                            </span>
                        </p>
                        <p class="control">
                            <button :class="{'is-primary': toaddtoset!=null}" :disabled="toaddtoset==null" @click="addpertoset" class="button">Add Personalisation</button>
                        </p>
                    </div>
                    
                </div>
                <div class="column is-narrow buttons">
                    <button class="button" @click="selectedset=null" v-if="selectedset==set">Cancel</button>
                    <button v-if="selectedset!=set" @click="selectedset=set" class="button">Edit</button>
                    <button v-if="selectedset==set" @click="saveset" class="button is-primary">Save</button>
                </div>
            </div>
            </template>
            <template v-else>
                <div v-for="pr in personalisations" class="columns is-striped">
                    <div class="column is-narrow">#{{pr.id}}</div>
                    <div v-if="selectedpr==pr" class="column">
                        <div class="field">
                            <input class="input" v-model="pr.name" />
                        </div>
                        <div class="field">
                            <textarea class="input" placeholder="Note goes here" v-model="pr.note"></textarea>
                        </div>
                    </div>
                    <div class="column" v-else>
                        <strong>{{pr.name}}</strong>
                        <br />
                        {{pr.note}}
                    </div>
                    <div  v-if="selectedpr==pr" class="column">
                        <template v-if="pr.personalisationOptions.length == 0">
                        <div class="select">
                            <select v-model="pr.personalisationInputType">
                                <option value="1">Text Input</option>
                                <option value="2">Number Input</option>
                                <option value="3">True/False Input</option>

                            </select>
                        </div>
                        </template>
                    </div>
                    <div class="column" v-else>
                        <template v-if="pr.personalisationOptions.length == 0">
                            <span v-if="pr.personalisationInputType==1" class="tag is-warning">Free Text Entry</span>
                            <span v-if="pr.personalisationInputType==2" class="tag is-warning">Number Value</span>
                            <span v-if="pr.personalisationInputType==3" class="tag is-warning">True/False Value</span>
                        </template>
                    </div>
                    <div class="column tags">

                        <div :key="i" v-for="(op, i) in pr.personalisationOptions" class="tag is-info">{{op.name}} <button v-if="selectedpr==pr" @click="removeoption(pr, i)" class="delete"></button></div>
                        <div v-if="selectedpr==pr" class="field has-addons">
                            <p class="control">
                                <input :ref="`newopt${pr.id}`" v-on:keyup.enter="addoption(pr)" class="input is-small" placeholder="Add option" />
                            </p>
                            <p class="control">
                                <button @click="addoption(pr)" class="button is-small">Add</button>
                            </p>
                        </div>
                        
                    </div>
                    <div class="column is-narrow buttons">
                        <button @click="selectedpr=null" class="button" v-if="selectedpr==pr">Cancel</button>
                        <button v-if="selectedpr==pr" class="button is-primary" @click="savepersonalisation">Save</button>
                        <button v-else class="button" @click="selectedpr=pr">Edit</button>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
<style>
    .bordered-row { border: 1px solid #ddd; }
    .bordered-set { border-bottom: 4px solid #ebebeb; padding: 0.75rem 0; }
    .bordered-set:last-child { border: none; }
</style>

<script>
    import axios from 'axios'
    import BoolTick from './BoolTick.vue'
    /* eslint-disable */
    import YesNoBlob from './YesNoBlob.vue'
    export default {
        props: {
            
        },
        data() {
            return {
                personalisationsets: [],
                personalisations: [],
                selectedset: null,
                selectedpr: null,
                ontab: 'sets',
                toaddtoset: null
            }
        },
        emits: [],
        name: "PersonalisationAdmin",
        components: {
            YesNoBlob, BoolTick
        },
        mounted() {
            this.fetchpersonalisationsets()
            this.fetchpersonalisations()
        },
        computed: {},
        methods: {
            fetchpersonalisationsets() {
                axios.get('api/product/personalisationsets')
                    .then((response) => {
                        this.personalisationsets = response.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            fetchpersonalisations() {
                axios.get('api/product/personalisations')
                    .then((response) => {
                        this.personalisations = response.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            newpersonalisation() {
                let np = {
                    id: 0, name: '*New Personalisation*',
                    note: '',
                    personalisationInputType: 1,
                    personalisationOptions: []
                }
                this.personalisations.unshift(np)
                this.selectedpr = np
            },
            newset() {
                let ns = {
                    id: 0,
                    name: '*New Set*',
                    note: '',
                    personalisationInputType: 1,
                    personalisationSetPersonalisations: []
                }
                this.personalisationsets.unshift(ns)
                this.selectedset = ns
            },
            savepersonalisation() {
                axios.post('api/product/savepersonalisation', this.personalisations)
                    .then((response) => {
                        if (response && response.data) {
                            this.personalisations = response.data
                            this.selectedpr = null
                            this.personalisationsets.forEach(ps => {
                                ps.personalisationSetPersonalisations.forEach(psp => {
                                    psp.personalisation = this.personalisations.find(x => x.id == psp.personalisation.id)
                                })
                            })
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            saveset() {
                axios.post('api/product/savepersonalisationset', this.selectedset)
                    .then((response) => {
                        if (response && response.data) {
                            Object.assign(this.selectedset, response.data)
                            
                            this.selectedset = null
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            removefromset(id) {
                let ind = this.selectedset.personalisationSetPersonalisations.findIndex(c => c.personalisation.id == id)
                this.selectedset.personalisationSetPersonalisations.splice(ind,1)
            },
            addpertoset() {
                let len = this.selectedset.personalisationSetPersonalisations.length
                /*let opts = []
                if (this.toaddtoset.personalisationOptions && this.toaddtoset.personalisationOptions.length)
                    this.toaddtoset.personalisationOptions.forEach((v) => {
                        opts.push({
                            id: v.id, name: v.name
                        })
                    })
                */
                this.selectedset.personalisationSetPersonalisations.push({
                    id: 0,
                    personalisation: this.toaddtoset,
                    required: true,
                    sequence: (len + 1) * 100,
                    personalisationSetId: this.selectedset.id,
                    personalisationId: this.toaddtoset.id
                })
                this.toaddtoset = null
            },
            removeoption(pr, ind) {
                //let ind = pr.personalisationOptions.findIndex((po) => po.id == opt.id && opt.)
                pr.personalisationOptions.splice(ind, 1)
            },
            addoption(pr) {
                let v = this.$refs[`newopt${pr.id}`].value
                if (v && !pr.personalisationOptions.some(p => p.name.toLowerCase() == v.toLowerCase())) {
                    pr.personalisationOptions.push({
                        id: 0, name: v, personalisationId: pr.id
                    })
                    this.$refs[`newopt${pr.id}`].value = ''
                    this.$refs[`newopt${pr.id}`].focus()
                }
                else
                    if (v) {
                        this.$refs[`newopt${pr.id}`].value = ''
                        this.$refs[`newopt${pr.id}`].focus()
                    }
            }
        }
    }
</script>