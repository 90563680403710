<template>
    <header class="navbar is-fixed-top has-background-light not-printed">
        <div class="navbar-brand">
            <a role="button" @click="isactive=!isactive" :class="{'is-active': isactive === true}" class="navbar-burger" aria-label="menu" aria-expanded="false">
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
        </div>
        <div class="navbar-start">
        </div>
        <div class="navbar-menu" :class="{'is-active': isactive === true}">
            <div class="navbar-end">
                <router-link :to="{ name: 'Home' }" class="navbar-item">Home</router-link>
                <router-link :to="{ name: 'Orders' }" class="navbar-item">Orders</router-link>
                <router-link :to="{ name: 'Products' }" class="navbar-item">Products</router-link>
                <router-link :to="{ name: 'Offers' }" class="navbar-item">Offers</router-link>
                <router-link :to="{ name: 'PromoCodes' }" class="navbar-item">PromoCodes</router-link>
                <router-link :to="{ name: 'Categories' }" class="navbar-item">Cats</router-link>
                <router-link :to="{ name: 'Personalisations' }" class="navbar-item">Personalisations</router-link>
                <router-link :to="{ name: 'Login' }" class="navbar-item">Login</router-link>
            </div>
        </div>
    </header>
</template>


<style>

</style>
<script>
    export default {
        name: "NavMenu",
        components: {
        },
        data() {
            return {
                isactive: false
            }
        },
        methods: {
            collapse() {
                this.isExpanded = false;
            },

            toggle() {
                this.isExpanded = !this.isExpanded;
            }
        }
    }
</script>