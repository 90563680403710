<template>
    <nav-menu></nav-menu>
    <router-view />
</template>

<script>
    import NavMenu from './components/NavMenu.vue'

export default {
    name: 'App',
    data() {
        return {
            products: []
        }
    },
    components: {
    NavMenu
    }
}
</script>

<style>
#app {
}
</style>
