<template>
    <div class="product-admin">
        <div class="navbar is-info mb-6 not-printed">
            <div class="navbar-brand">
                <search></search>
            </div>
            <div class="navbar-end">
                <div class="navbar-item">
                    <div class="dropdown is-right" :class="{'is-active': menuactive}">
                        <div class="dropdown-trigger">
                            <a class="button is-small is-text mr-4" href="https://www.colourtheirday.com/Home/ClearCache?key=currentoffers">Clear Offer Cache</a>
                            <button @click="menuactive = !menuactive" class="button" aria-haspopup="true" aria-controls="dropdown-menu">
                                <span>Add New</span>
                            </button>
                        </div>
                        <div class="dropdown-menu" id="dropdown-menu" role="menu">
                            <div class="dropdown-content">
                                <a @click="templateoffer('moneyoffpromo')" href="#" class="dropdown-item">
                                    Money Off with PromoCode
                                </a>
                                <a @click="templateoffer('moneyoffsale')" class="dropdown-item">
                                    Money Off (Sale)
                                </a>
                                <a @click="templateoffer('colourclubdiscount')" href="#" class="dropdown-item">
                                    Colour Club Discount
                                </a>
                                <a @click="templateoffer('multibuy')" href="#" class="dropdown-item">
                                    Multi-Buy
                                </a>
                                <a @click="templateoffer('buyxgety')" href="#" class="dropdown-item">
                                    Buy X Get Y
                                </a>
                                <hr class="dropdown-divider">
                                <a @click="templateoffer('newoffer')" href="#" class="dropdown-item">
                                    New Offer
                                </a>

                                <hr class="dropdown-divider">
                                <a @click="templateoffer('freedelivery')" href="#" class="dropdown-item">
                                    Free Delivery
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <template v-if="!selectedoffer">
                <div class="columns is-striped" v-for="(off, i) in offers" :key="i">
                    <div class="column is-narrow">#{{off.id}}</div>
                    <div class="column">
                        <strong>{{off.headline}}</strong>
                        <p>
                            {{off.description}}
                        </p>
                    </div>
                    <div class="column">
                        {{offertypes[off.offerType].name}}
                    </div>
                    <div class="column">
                        {{off.startDate.toLocaleString('en-GB')}}
                    </div>
                    <div class="column">
                        {{off.endDate.toLocaleString('en-GB')}}
                    </div>
                    <div  class="column is-narrow"><button @click="getoffer(off.id)" class="button">Edit</button></div>
                </div>
            </template>
            <offer-edit v-else @doSave="saveoffer" @cancelSave="savewascancelled" :types="offertypes" :offer="selectedoffer"></offer-edit>
        </div>
        <offer-templater @doSaveTemplated="saveoffer" @close-templater="() => offertemplate=null" :template="offertemplate"></offer-templater>
    </div>
</template>

<script>
    import axios from 'axios'
    import Search from './Search.vue'
    import OfferEdit from './OfferEdit.vue'
    import OfferTemplater from './OfferTemplater.vue'
    //import PackingSlipDisplay from './PackingSlipDisplay'

    export default {
        name: "OfferAdmin",
        components: {
            OfferEdit,
            Search,
            OfferTemplater
            //PackingSlipDisplay
            //YesNoBlob
        },
        data() {
            return {
                offers: [],
                offertypes: [
                    { name: 'None' },// = 0, // 0
                    { name: 'PercentageOff', valueLabel:'% Discount' },// = 1, // 1 
                    { name: 'AmountOff', valueLabel:'Amount Off' },// = 2, // 2 
                    { name: 'FixedPrice', valueLabel:'Offer Price' },// = 3, // 3 
                    { name: 'FreeItems', valueLabel:'Discount Value' },// = 4, // 4 
                    { name: 'OrderValuePercentReduction', valueLabel:'% Off Order' },// = 5, // 5 
                    { name: 'OrderValueAmountReduction', valueLabel:'Amount Off Order' },// = 6, // 6 
                    { name: 'DeliveryPercentageReduction', valueLabel:'% Off Delivery' },// = 7, // 7 
                    { name: 'DeliveryAmountReduction', valueLabel:'Amount Off Delivery' },// = 8, // 8 
                    { name: 'DeliveryFixedPrice', valueLabel:'Delivery Offer Price' },// = 9, // 9 
                    { name: 'DeliveryZeroPrice', valueLabel:'Discount Value'  },// = 10, // 10 
                    { name: 'AlternatePricelist', valueLabel:'Discount Value'  },// = 11 // 11
                ],
                from: '',
                to: '',
                selectedoffer: null,
                selected: [],
                disabled: false,
                menuactive: false,
                offertemplate: null,
                templateractive: false
            }
        },
        methods: {
            fetchoffers() {
                axios.get(`api/offers`)
                    .then((response) => {
                        this.offers = response.data;
                        this.offers.forEach(v => {
                            v.startDate = new Date(v.startDate)
                            v.endDate = new Date(v.endDate)
                        })
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            saveoffer(off) {
                this.selectedoffer = off
                this.offertemplate = null

                let updated = this.offers.findIndex(x => x.id == off.id)
                if (updated > -1)
                    this.offers[updated] = off
                else 
                    this.offers.push(off)
            },
            savewascancelled() {
                this.selectedoffer = null
            },
            getnewoffer() {
                this.getoffer(0);
            },
            getoffer(id) {
                axios.get(`api/offers/${id}`)
                    .then((response) => {
                        this.selected = [response.data.id];
                        this.selectedoffer = response.data;
                        this.selectedoffer.startDate = this.selectedoffer.startDate.substr(0, 19)
                        this.selectedoffer.endDate = this.selectedoffer.endDate.substr(0, 19)
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            fetchpermissions() {
                axios.get(`api/offers/permissionqualifiers`)
                    .then((response) => {
                        this.offers = response.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            templateoffer(typ) {
                this.menuactive = false
                this.offertemplate = typ
            }
        },
        mounted() {
            this.fetchoffers()
            //this.fetchpermissions()
        },
    }
</script>