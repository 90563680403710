<template>
    <div class="field search-control mb-0 has-addons">
        <div class="control">
            <input class="input" incremental v-on:search="dosearch" placeholder="Search..." type="search" v-model="q" />
        </div>
        <p class="control">
            <button @click="dosearch" class="button is-primary">Go</button>
        </p>
    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        name: 'Search',
        data() {
            return {
                q: '',
                products: []
            }
        },
        components: {
            
        },
        methods: {
            dosearch() {
                if (this.q) {
                    axios.get('/api/product/search?q=' + this.q)
                        .then((response) => {
                            this.$emit('search-products', response.data.model)
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
                else {
                    this.$emit('search-reset')
                }
            }
        }
    }
</script>

<style>
    .search-control {
        padding: 0.5rem 1rem;
    }
</style>
