<template>
    <div class="product-admin">
        <div class="navbar is-info mb-6">
            <div class="navbar-brand">
                <search @search-reset="fetchproducts" @search-products="(p) => { products = p; count=p.length; insearch=true; selectedproduct=null  }"></search>
            </div>
            <div class="navbar-menu">
                <div class="navbar-start">
                    <div class="navbar-item publish-filter">
                        <label class="radio">All <input type="radio" :value="null" name="filterpublished" v-model="filter.published" /></label>
                        <label class="radio">Published <input type="radio" :value="true" name="filterpublished" v-model="filter.published" /></label>
                        <label class="radio">Unpublished <input type="radio" :value="false" name="filterpublished" v-model="filter.published" /></label>
                    </div>
                </div>
                <div class="navbar-end">
                    <div class="navbar-item">
                        <div class="field is-grouped">
                            <p class="control">
                                <button class="button" @click="getnewproduct">Add New</button>
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="container">

            <template v-if="!selectedproduct">
                <div class="columns is-striped" v-for="(prod, i) in filteredproducts" :key="i">
                    <div v-if="prod.productImages.length>0" class="column is-narrow">
                        <img loading="lazy" width="64" height="64" :src="`${imgbase}/${prod.productImages[0].slug}?format=webp&width=128`" />
                    </div>
                    <div class="column is-narrow">
                        #{{prod.id}}
                    </div>
                    <div class="column">
                        {{prod.name}}
                    </div>
                    <div v-if="prod.productType" class="column">
                        {{prod.productType.name}}
                    </div>
                    <div v-else class="column">
                        unknown type
                    </div>
                    <div  class="column is-narrow">
                        <yes-no-blob :value="prod.published"></yes-no-blob>
                    </div>
                    <div  class="column is-narrow"><button @click="getproduct(prod.id)" class="button">Edit</button></div>
                </div>
            </template>

            <product-edit @doSave="saveproduct" @cancelSave="selectedproduct = null" v-else :product="selectedproduct" :types="types" :templates="templates" :imgbase="imgbase" :uploadurl="uploadurl" ></product-edit>

        </div>

        <footer v-if="insearch==false && !selectedproduct" class="has-background-light">
            <button class="button" @click="currentpage--" :class="{'is-disabled':currentpage==1}">Prev Page</button>
            <div class="select">
                <select v-model="currentpage">
                    <option v-for="page in Math.ceil(count/pagesize)" :value="page" :key="page">{{page}}</option>
                </select>
            </div>
            <button class="button"  @click="currentpage++" :class="{'is-disabled':currentpage==Math.floor(count/pagesize)}">Next page</button>
        </footer>
    </div>
</template>

<script>
    /* eslint-disable */
    import axios from 'axios'
    import ProductEdit from './ProductEdit.vue'
    import YesNoBlob from './YesNoBlob.vue'
    import Search from './Search.vue'
    export default {
        name: "ProductsAdmin",
        components: {
            ProductEdit,
            Search,
            YesNoBlob
        },
        data() {
            return {
                currentpage: 1,
                products: [],
                types: [],
                templates: [],
                selectedproduct: null,
                count: 0,
                filter: { published: null },
                pagesize: 30,
                insearch: false,
                imgbase: `${this.$host}/images/product/`,
                uploadurl: this.$host
            }
        },
        computed: {
            filteredproducts() {
                if(this.filter.published != null)
                    return this.products.filter(p=>p.published == this.filter.published)
                else
                    return this.products
            }
        },
        methods: {
            saveproduct(p) {
                this.selectedproduct = p
            },
            getnewproduct() {
                this.getproduct(0)
            },
            getproduct(id) {
                axios.get(`/api/product/${id}`)
                    .then((response) => {
                        if (response && response.data) {
                            this.selectedproduct = response.data;
                            this.selectedproduct.skus.forEach((sk) => {
                                if (!sk.skuPrices.length) {
                                    sk.skuPrices.push({
                                        id: 0,
                                        skuId: sk.id,
                                        currencyId: 1,
                                        priceListId: 1,
                                        proice: 999.99,
                                        breakquantity: 0
                                    });
                                    sk.skuPrices.push({
                                        id: 0,
                                        skuId: sk.id,
                                        currencyId: 1,
                                        priceListId: 2,
                                        proice: 999.99,
                                        breakquantity: 0
                                    })
                                }
                            })
                        } else {
                            console.log(response);
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            fetchproducts() {
                this.insearch = false
                //this.selectedproduct = null
                axios.get('/api/product?t=' + this.pagesize + '&p=' + this.currentpage)
                    .then((response) => {
                        if (response && response.data && response.data.model) {
                            this.products = response.data.model;
                            this.count = response.data.count
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            fetchproducttypes() {
                axios.get('/api/product/types')
                    .then((response) => {
                        this.types = response.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            fetchtemplates() {
                axios.get('/api/product/templates')
                    .then((response) => {
                        this.templates = response.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            fetchpersonalisationsets() {
                axios.get('/api/product/personalisationsets')
                    .then((response) => {
                        this.personalisationsets = response.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        },
        mounted() {
            this.fetchproducts()
            this.fetchproducttypes()
            this.fetchtemplates()
        },
        watch: {
            currentpage() {
                if (!this.insearch)
                    this.fetchproducts()
            }
        }
    }
</script>

<style>
    footer { 
        padding: 1.5rem;
        position: fixed; left: 0; right: 0; bottom: 0; 

    }
    .search-nav {
        display: flex;    align-items: center;
        
        margin-bottom: 0 !important;
    }
    .product-admin { margin: 3.25rem 0 8rem; }
    .publish-filter { }
    .is-striped:nth-child(even) { background-color: #f7f7f7; }
</style>
